
/* eslint-disable */
import { defineComponent, toRef, watch, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import formatDate from 'xe-utils/toDateString'
import { assign } from "xe-utils";
import QrCode from "@/components/modal/QrCode.vue";
import { Input } from 'vxe-table'

export default defineComponent({
  components: {
    QrCode
  },
  props: {
    visible: { type: Boolean, default: true },
    id: Number,
    data: {},
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const open = toRef(props, "visible");
    const confirmOpen = ref(false);
    const row = ref<any>({});
    const showQrcode = ref(false);

    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    watch(() => props.visible, async (value: any) => {
      if(value) {
        row.value = {};
        //var data = props.data as any;
        const entity = props.id ? await model!.dispatch('order/find', props.id) : undefined
        row.value = entity;
        row.value.InsuranceRecords = await model?.dispatch("insuranceRecord/query", { condition: new Condition("OrderId", Operator.Equal, row.value.Id) })
        // assignModal.returnNumber = [];
        // row.value.Items.forEach((e: any) => {
        //   if(e.BikeId && e.Bike) assignModal.returnNumber.push(e.Bike.Number);
        // });
        // console.log(assignModal.returnNumber)
        console.log(row.value)
        //console.log('edit', row.value)
        // if(couponGrid.value) {
        //   couponGrid.value.keyword = null;
        //   await couponGrid.value.refresh();
        // }
        // if(orderGrid.value) {
        //   orderGrid.value.keyword = null;
        //   await orderGrid.value.refresh();
        // }
        // if(bookItemGrid.value) {
        //   bookItemGrid.value.keyword = null;
        //   await bookItemGrid.value.refresh();
        // }
      }
    }, { immediate: true });

    const qrcodeDecode = async (value: any) => {
      console.log(value)
      if(value) {
        assignModal.number = value;
        await assignModal.findBike();
      }
    };

    const number_input = ref<any>(null);
    const assignModal = reactive({
      visible: true,
      selectedRow: {} as any,
      id: 0,
      number: '',
      returnNumber:[] as any[],
      show(row: any) {
        //console.log(row)
        assignModal.visible = true;
        assignModal.selectedRow = row;
        assignModal.id = row.Id;
        number_input.value?.focus();
      },
      calculator(num: number) {
        var result = assignModal.number.toString() + num.toString();
        assignModal.number = result;
      },
      findBike: async() => {
        try {
          const number = assignModal.number;
          if(number) {
            const condition = new Condition("Number", Operator.Equal, number)
            const bike = (await model?.dispatch('bike/query', { condition }))?.[0];
            // console.log(bike)
            // console.log(bike.ProductId, assignModal.selectedRow.ProductId)
            if (bike) {
              //if(assignModal.selectedRow.BikeId && assignModal.selectedRow.Bike) assignModal.returnNumber.push(assignModal.selectedRow.Bike.Number);
              const repeat = row.value.Items.some((e: any) => e.Bike && e.Bike.Number == bike.Number);
              if(repeat) {
                CloudFun.send("error", {
                  subject: "執行失敗",
                  content: "存在相同車輛編號"
                });
                return;
              }

              if(bike.ProductId == assignModal.selectedRow.ProductId) {
                assignModal.selectedRow.BikeId = bike.Id;
                assignModal.selectedRow.Bike = bike;
                assignModal.reset();
              } else {
                CloudFun.send("error", {
                  subject: "執行失敗",
                  content: "車輛類型錯誤"
                });
              }
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "查無車輛資訊"
              });
            }
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛編號"
            });
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          });
        }
      },
      checkAssign: async() => {
        try {
          //var numbers = row.value.Items.filter((e: any) => (e.Product && e.Product.Type == 0 || e.BikeId) && e.Bike).map((e: any) => e.Bike?.Number);
          var bikes = row.value.Items.filter((e: any) => (e.Product && e.Product.Type == 0 || e.BikeId) && e.Bike).map((e: any) => {return { Number: e.Bike.Number, ProductId: e.Bike.ProductId }} );
          console.log(bikes)
          var repeat = bikes.map((e: any) => e.Number).filter((e: any, index: any, arr: any) => {
             return arr.indexOf(e) !== index;
          });

          if(repeat && repeat.length > 0) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "存在相同車輛編號"
            });
            confirmOpen.value = false;
            return;
          }

          // if(assignModal.returnNumber.length > 0) {
          //   console.log('需要先還車', assignModal.returnNumber);
          //   await model?.dispatch('order/return', { id: row.value.Id, bikeNos: assignModal.returnNumber });
          //   assignModal.returnNumber = [] as any[];
          // }

          await model?.dispatch('order/depart', { id: row.value.Id, stationId: row.value.StationId, bikes: bikes }).then((data) => {
            assignModal.reloadData()
          });
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          });
        }
        confirmOpen.value = false;
      },
      reset(){
        assignModal.number = '';
        //assignModal.visible = false;
        assignModal.selectedRow = {} as any;
        assignModal.id = 0;
      },
      close() {
        assignModal.reset();
        emit("dismiss");
      },
      reloadData() {
        emit("reloadData");
      }
    })

    function addHours(numOfHours: any, date: any) {
      date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
      return date;
    }

    return {
      open,
      confirmOpen,
      row,
      formatDate,
      assignModal,
      showQrcode,
      qrcodeDecode,
      number_input,
      addHours,
      insurance: [
        {
          id: 1,
          name: "林先生",
          userId: "E121045677",
          bday: "1991/01/05",
          tel: "0988030000"
        },
        {
          id: 2,
          name: "王小姐",
          userId: "E225045612",
          bday: "1971/11/15",
          tel: "0988123000"
        },
      ],
      bikeList: [
        {
          id: 1,
          name: "單人電輔車",
          number: "尚未派車",
          numberClass: "text-gray-300",
          active: "派車",
          activeClass: "text-theme-4 border-theme-4",
        },
        {
          id: 2,
          name: "單人電輔車",
          number: "224A",
          numberClass: "text-theme-4",
          active: "重新派車",
          activeClass: "text-gray-400 border-gray-300",
        },
      ]
    };
  },
  methods: {
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});

