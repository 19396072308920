
import { defineComponent, ref, reactive, onMounted } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";

export default defineComponent({
  name: "StationSelect",
  emits: ["change"],
  setup(_, { emit }) {
    const model = CloudFun.current?.model;
    const query = reactive({ area: 0, station: 0 });
    const areas = ref<{ label: string; value: number; }[]>([]);
    const stations = ref<any[]>([]);

    onMounted(async () => {
      areas.value = await model?.dispatch("area/options");
    })

    const changeArea = async (id: string) => {
      query.area = parseInt(id);
      query.station = 0;
      stations.value = await model?.dispatch("station/query", { condition: new Condition("AreaId", Operator.Equal, query.area) });
      emit("change", query);
    }

    const changeStation = async (id: string) => {
      query.station = parseInt(id);
      emit("change", query);
    }

    return {
      query,
      areas,
      stations,
      changeArea,
      changeStation
    }
  },
})
