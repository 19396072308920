
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive, watch } from "vue";
import CloudFun, { Model, Condition, Sorting, SortOrder, Operator } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import LineChart from "@/components/line-chart/Main.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import router from "@/router";
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar, { CalendarOptions } from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import QrCode from "@/components/modal/QrCode.vue";
import BikeCode from "@/components/modal/BikeCode.vue";
import OrderBike from "@/views/bike/orderBike/orderModal/Main.vue";
import ReturnBike from "@/views/bike/returnModal/Main.vue";
import formatDate from 'xe-utils/toDateString';
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import StationSelect from "@/components/order/StationSelect.vue";

interface FilterParam {
  Number?: string;
  StartTime?: string;
  EndTime?: string;
  Area?: number;
  Station?: number;
}

const queryToCondition = (condition: Condition, query: FilterParam) => {
  condition.and("DataMode", Operator.Equal, 0)
    .and("Status", Operator.NotEqual, 2).and("Status", Operator.NotEqual, 31);
  if (query.Number) condition.and("Number", Operator.Equal, query.Number);
  if (query.StartTime) condition.and("BookingTime", Operator.GreaterThanOrEqual, query.StartTime);
  if (query.EndTime) condition.and("BookingTime", Operator.LessThanOrEqual, query.EndTime);
  if (query.Area) condition.and("Station.AreaId", Operator.Equal, query.Area);
  if (query.Station) condition.and("StationId", Operator.Equal, query.Station);
}

export default defineComponent({
  components: {
    PieChart,
    LineChart,
    Loading,
    FullCalendar,
    Grid,
    QrCode,
    BikeCode,
    ReturnBike,
    OrderBike,
    SelectBox,
    StationSelect
  },
  setup() {
    const model = CloudFun.current?.model;
    const showQrcode = ref(false);
    const showBikecode = ref(false);
    const oderBike = ref(false);
    const returnBike = ref(false);
    const grid = ref<any>({});
    const today = new Date();
    const filters = ref({
      StartTime: formatDate(today, "yyyy/MM/dd"),
      EndTime: formatDate(today, "yyyy/MM/dd 23:59:59"),
    } as FilterParam);
    const deleteConfirm = ref(false);
    const rowId = ref(0);
    const gridOptions: GridOptions = {
      stripe: false,
      title: "今日訂單",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "今日訂單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Number",
          title: "取車編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "100"
        },
        {
          field: "BookingTime",
          title: "預計取車時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "200",
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm') : ""
        },
        {
          field: "Name",
          title: "聯絡人",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "100"
        },
        {
          title: "配車數量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "100",
          formatter: ({ row }) => {
            if (row.Items) {
              var bike = row.Items.filter((e: any) => e.Product && e.Product.Type == 0);
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "加購項目",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "100",
          formatter: ({ row }) => {
            if (row.Items) {
              var item = row.Items.filter((e: any) => e.Product && e.Product.Type == 1);
              return item && item.length ? '有' : '無';
            } else return '無';
          }
        },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: "250",
          fixed: "right",
          slots: { default: "column-operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'Time', order: 1 });
          }
          queryToCondition(params.condition!, filters.value);
          return model.dispatch('order/query', params)
        } : undefined,
        save: model
          ? (params) => model.dispatch('order/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };
    const gridWay = ref<any>({});
    const gridOptionsWay: GridOptions = {
      stripe: false,
      title: "在途車輛",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "在途車輛",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "100"
        },
        // {
        //   field: "StartTime",
        //   title: "開始騎乘時間",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   minWidth: "150",
        // },
        // {
        //   field: "Time",
        //   title: "已騎乘時間(分鐘)",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   width: "150"
        // },
        {
          field: "Name",
          title: "聯絡人",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: "100"
        },
        {
          field: "Phone",
          title: "聯絡電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: "100"
        },
        {
          field: "Station",
          title: "起始站點",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "150"
        },
        {
          field: "ReturnStation",
          title: "預計歸還站點",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "150"
        },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: "200",
          fixed: "right",
          slots: { default: "column-operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          return model.dispatch('order/orderBikes', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('order/orderBikes') : undefined,
      },
      modalConfig: { showFooter: true }
    };
    var load = reactive({
      isLoading: false,
      fullPage: false,
    });

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: "right",
      // size: "medium",
      items: [
        {
          field: "Name",
          title: "聯絡人",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入聯絡人", clearable: true },
          }
        },
        {
          field: "Phone",
          title: "連絡電話",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入連絡電話" }
          }
        },
        // {
        //   field: "AlternatePhone",
        //   title: "備用連絡電話",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入備用連絡電話" }
        //   }
        // },
        // {
        //   field: "Email",
        //   title: "Email",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入聯絡Email" }
        //   }
        // },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.OrderStatus).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : [],
            props: { type: "text", placeholder: "請選擇狀態" }
          }
        },
        {
          field: "StoreId",
          title: "預約店家",
          span: 12,
          slots: { default: "column-store-id" }
        },
        {
          field: "StationId",
          title: "取車點",
          span: 12,
          slots: { default: "column-station-id" }
        },
        {
          field: "ReturnStationId",
          title: "歸還點",
          span: 12,
          slots: { default: "column-return-station-id" }
        },
        {
          field: "BookingTime",
          title: "預約取車時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime" }
          }
        },
        {
          field: "BookingExpiryTime",
          title: "預約保留期限",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "StartTime",
          title: "取車時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "EndTime",
          title: "還車時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { type: "text", placeholder: "請輸入負責人姓名" }
          }
        },
        {
          field: "ShippingAmount",
          title: "甲乙地費用",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", disabled: true }
          }
        },
        {
          field: "Amount",
          title: "總金額",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", disabled: true }
          }
        },
      ],
      rules: {
        Name: [{ required: true }],
        // Email: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.itemValue && CloudFun.utils.validator.validateEmail(params.itemValue)) return new Error("Email格式錯誤");
        //   }
        // }],
        Phone: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue || CloudFun.utils.validator.validatePhoneNumber(params.itemValue)) return new Error("手機格式錯誤");
          }
        }],
        // AlternatePhone: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.itemValue && CloudFun.utils.validator.validatePhoneNumber(params.itemValue)) return new Error("手機格式錯誤");
        //   }
        // }],
        StationId: [{ required: true }],
        ReturnStationId: [{ required: true }],
        // StoreId: [{ required: true }],
      }
    };

    const itemsGridOptions: GridOptions = {
      mode: "inline",
      multiselect: false,
      autoResize: true,
      columns: [
        {
          field: "ProductId", title: "產品", sortable: true,
          slots: { default: "column-product-id" },
        },
        {
          field: "Price", title: "價格", sortable: true, width: "100", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0 },
            events: {
              input: (params, event) => { params.row.Price = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Quantity", title: "時數/數量", sortable: true, width: "120", align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 1 },
            events: {
              input: (params, event) => { params.row.Quantity = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Amount", title: "金額", sortable: true, width: "100", headerAlign: "left", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
        },
      ],
      promises: {
        query: model
          ? params => {
            params.condition = new Condition(
              "OrderId",
              Operator.Equal,
              grid.value.editingRow?.Id || 0
            ).and(params.condition!);
            return model.dispatch("orderItem/query", params);
          }
          : undefined, // eslint-disable-line
        queryAll: model ? () => model.dispatch("orderItem/query") : undefined,
        save: model
          ? (params) => model.dispatch("orderItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('order/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); })
          : undefined
      }
    };

    const insuranceGridOptions: GridOptions = {
      mode: "inline",
      multiselect: false,
      autoResize: true,
      columns: [
        {
          field: "PersonalId",
          title: "身份證字號",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", immediate: true }
        },
        {
          field: "BirthDate",
          title: "生日",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", props: { type: "date" }, immediate: true },
          formatter: ({ cellValue }) => CloudFun.utils.formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "Name",
          title: "姓名",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", immediate: true }
        },
        {
          field: "MobilePhone",
          title: "手機",
          showOverflow: true,
          sortable: true,
          editRender: { name: "$input", immediate: true },
          formatter: ({ cellValue }) => CloudFun.utils.formatPhone(cellValue)
        }
      ],
      editRules: {
        PersonalId: [{ required: true, message: "未輸入身分證字號" }],
        Name: [{ required: true, message: "未輸入姓名" }],
        BirthDate: [{ required: true, message: "未選擇生日" }],
        MobilePhone: [{
          required: true,
          message: "未輸入手機",
          validator: (params) => {
            if (!params.cellValue || CloudFun.utils.validator.validatePhoneNumber(params.cellValue)) return new Error("手機格式錯誤");
          }
        }]
      },
      promises: {
        query: model
          ? (params) => {
            params.condition = new Condition(
              "OrderId",
              Operator.Equal,
              grid.value.editingRow?.Id || 0
            ).and(params.condition!);
            return model.dispatch('insuranceRecord/query', params)
          }
          : undefined, // eslint-disable-line
        save: model
          ? params => model.dispatch("insuranceRecord/save", params)
          : undefined
      }
    };

    const stationIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇站點",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("station/find", value), // eslint-disable-line
        query: params => model!.dispatch("station/query", params) // eslint-disable-line
      }
    };

    const storeIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇商家",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("store/find", value), // eslint-disable-line
        query: params => model!.dispatch("store/query", params) // eslint-disable-line
      }
    };

    const productIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇產品",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        },
        {
          field: "SalePrice",
          title: "售價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params => model!.dispatch("product/query", params) // eslint-disable-line
      }
    };

    const fullCalendar = ref<any>();
    const calendarOptions: CalendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: 'zh-tw',
      height: 560,
      events(info, successCallback) {
        const condition = new Condition();
        queryToCondition(condition, { StartTime: formatDate(info.start, "yyyy/MM/dd"), EndTime: formatDate(info.end, "yyyy/MM/dd 23:59:59") })
        model?.dispatch("order/query", { condition }).then((data: any) => {
          const dates: { [k: string]: any } = [];
          data.forEach((e: any) => {
            const time = formatDate(e.BookingTime, "yyyy-MM-dd");
            if (!dates[time]) {
              dates[time] = {
                title: "有訂單",
                // daysOfWeek: [e.DayOfWeek],
                start: time,
                // icon: "iconsminds-reload",
                // end: "2022-12-17",
                // display: "background",
                // content: "",
                // eventType: 8,
                color: "rgba(90, 90, 90, 0.3)",
              }
            }
          })
          successCallback(Object.values(dates));
        });
      },
      // dateClick(e: any) {
      //   console.log()
      //   queryDate.value = formatDate(new Date(e.dateStr), "yyyy/MM/dd");
      //   grid.value.refresh();
      // },
      eventClick(info) {
        filters.value.StartTime = formatDate(info.event.startStr, "yyyy/MM/dd");
        filters.value.EndTime = formatDate(info.event.startStr, "yyyy/MM/dd 23:59:59");
        grid.value.refresh();
      },
    }

    const loadLitepickerEvents = (date: Date) => {
      const startDate = new Date(date.getTime());
      startDate.setDate(1);
      const endDate = new Date(startDate.getTime());
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      const condition = new Condition();
      queryToCondition(condition, {
        StartTime: formatDate(startDate, "yyyy/MM/dd"),
        EndTime: formatDate(endDate, "yyyy/MM/dd 23:59:59")
      })
      model?.dispatch("order/query", { condition }).then((data: any) => {
        litepicker.value.setHighlightedDays(data.map((e: any) => formatDate(e.BookingTime, "yyyy-MM-dd")));
      });
    };

    const litepicker = ref<any>();
    const litepickerOptions: any = {
      autoApply: false,
      showWeekNumbers: true,
      dropdowns: {
        minYear: today.getFullYear(),
        maxYear: today.getFullYear() + 1,
        months: true,
        years: true,
      },
      buttonText: {
        apply: "加入",
        cancel: "取消"
      },
      format: "YYYY/MM/DD",
      lang: "zh-TW",
      onChangeMonth({ dateInstance }: any) {
        loadLitepickerEvents(dateInstance)
      },
      onChangeYear({ dateInstance }: any) {
        loadLitepickerEvents(dateInstance)
      }
    }

    onMounted(async () => {
      const todayBtn = document.getElementsByClassName('fc-today-button')[0]; //today button query
      todayBtn?.addEventListener('click', async function () {
        const date = new Date();
        filters.value.StartTime = formatDate(date, "yyyy/MM/dd");
        filters.value.EndTime = formatDate(date, "yyyy/MM/dd 23:59:59");
        await grid.value.refresh();
      });
      loadLitepickerEvents(new Date());
    })

    const queryModel = reactive({
      visible: false,
      type: 0,
      //number: '',
      show(type: number) {
        queryModel.type = type;
        queryModel.visible = true;
      },
      findOrder: async (number: any, searchId = 0) => {
        try {
          console.log('查詢訂單:', number, searchId)
          if (number) {
            const sortings = new Sorting('CreatedTime', SortOrder.Descending);
            filters.value.Number = number;
            const condition = new Condition();
            if(searchId > 0) {
              condition.and("Id", Operator.Equal, searchId);
            } else {
              queryToCondition(condition, filters.value);
            }
            const order = (await model?.dispatch('order/query', { condition, sortings, page: 1, pageSize: 10 }))?.data?.[0];
            console.log('訂單:', order)
            if (order) {
              if (queryModel.type == 0) {
                if (order.Status == 31) {
                  CloudFun.send("error", {
                    subject: "執行失敗",
                    content: "訂單已完成，請瀏覽訂單管理"
                  });
                  return;
                }
                oderBikeModal.show(order);
              } else if (queryModel.type == 1) {
                returnBikeModal.show(order);
              }

              queryModel.reset();
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "查無此訂單"
              });
            }
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          });
        } finally {
          delete filters.value.Number;
        }
      },
      reset() {
        queryModel.visible = false;
      }
    })

    const oderBikeModal = reactive({
      visible: false,
      selectedRow: {} as any,
      id: 0,
      show(row: any) {
        //console.log(row)
        oderBikeModal.visible = true;
        oderBikeModal.selectedRow = row;
        oderBikeModal.id = row.Id;
      },
      delete() {
        if (rowId.value) {
          model?.dispatch('order/delete', rowId.value).then(() => {
            grid.value.refresh();
            deleteConfirm.value = false;
            rowId.value = 0;
          }).catch((error: any) => {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error
            });
            deleteConfirm.value = false;
            rowId.value = 0;
          });
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得訂單資訊"
          });
        }
      },
      reloadData() {
        //console.log('reloadData', row);
        //oderBikeModal.selectedRow = row;
        oderBikeModal.visible = false;
        grid.value.refresh();
        if (gridWay.value.refresh) gridWay.value.refresh();
        CloudFun.send("info", {
          subject: "執行成功",
          content: "完成配車"
        });
      }
    });

    const returnBikeModal = reactive({
      visible: false,
      selectedRow: {} as any,
      id: 0,
      show(row: any) {
        //console.log(row)
        returnBikeModal.visible = true;
        returnBikeModal.selectedRow = row;
        returnBikeModal.id = row.Id;
      },
      delete() {
        if (rowId.value) {
          model?.dispatch('order/delete', rowId.value).then(() => {
            grid.value.refresh();
            deleteConfirm.value = false;
            rowId.value = 0;
          }).catch((error: any) => {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error
            });
            deleteConfirm.value = false;
            rowId.value = 0;
          });
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得訂單資訊"
          });
        }
      },
      reloadData() {
        returnBikeModal.visible = false;
        grid.value.refresh();
        if (gridWay.value.refresh) gridWay.value.refresh();
        CloudFun.send("info", {
          subject: "執行成功",
          content: "完成還車"
        });
      }
    });

    const qrcodeDecode = async (value: any) => {
      console.log(value)
      queryModel.type = 0; //派車
      if (value) await queryModel.findOrder(value, 0);
    };

    const onWayClick = async (row: any) => {
      console.log(row)
      queryModel.type = 1; //還車
      if (row.OrderNumber) await queryModel.findOrder(row.OrderNumber, row.OrderId);
    };

    const toOrder = (number: any) => {
      setTimeout(() => {
        const el = document.getElementById('menu-order');
        window.localStorage.setItem("OrderNumber", number);
        if (el) {
          el.click();
        } else {
          router.push('/order');
        }
      }, 150);
    }
    return {
      load,
      fullCalendar,
      calendarOptions,
      litepicker,
      litepickerOptions,
      grid,
      gridWay,
      gridOptions,
      showQrcode,
      showBikecode,
      oderBike,
      oderBikeModal,
      returnBikeModal,
      queryModel,
      qrcodeDecode,
      deleteConfirm,
      rowId,
      returnBike,
      gridOptionsWay,
      formOptions,
      itemsGridOptions,
      insuranceGridOptions,
      stationIdSelectOptions,
      storeIdSelectOptions,
      productIdSelectOptions,
      onWayClick,
      toOrder,
      filters
    };
  },
  methods: {
    onDatepickerChanged(value: string) {
      const date = new Date(value);
      this.filters.StartTime = formatDate(date, "yyyy/MM/dd");
      this.filters.EndTime = formatDate(date, "yyyy/MM/dd 23:59:59");
      this.grid.refresh();
    }
  }
});
