
import { defineComponent, toRef, watch, reactive } from "vue";

export default defineComponent({
  components: {
  },
  props: {
    title: String,
    visible: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const open = toRef(props, "visible");

    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    watch(() => props.visible, async (value: any) => {
      if (value) {
        codeModel.number = '';
      }
    }, { immediate: true });

    const codeModel = reactive({
      number: '',
      calculator(num: number) {
        var result = codeModel.number.toString() + num.toString();
        codeModel.number = result;
      },
      check() {
        emit("order", codeModel.number, 0);
      },
    })

    return { open, codeModel };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    order() {
      this.$emit("order");
    },
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});
