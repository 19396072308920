import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createBlock(_component_Chart, {
    type: "line",
    width: _ctx.width,
    height: _ctx.height,
    data: _ctx.data,
    options: _ctx.options
  }, null, 8, ["width", "height", "data", "options"]))
}