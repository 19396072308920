import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full"
}
const _hoisted_2 = { class: "grid grid-cols-5 gap-5 h-full" }
const _hoisted_3 = { class: "col-span-5 md:col-span-3 m-2 bg-gray-100 flex justify-center items-center h-full" }
const _hoisted_4 = { class: "text-4xl font-bold text-theme-1" }
const _hoisted_5 = { class: "col-span-5 md:col-span-2 flex items-center" }
const _hoisted_6 = { class: "w-full grid grid-cols-3 gap-1 mb-2" }
const _hoisted_7 = {
  type: "button",
  disabled: "",
  class: "number-btn"
}
const _hoisted_8 = { class: "flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesome = _resolveComponent("FontAwesome")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_vxe_modal = _resolveComponent("vxe-modal")!

  return (_openBlock(), _createBlock(_component_vxe_modal, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.open) = $event)),
    title: _ctx.title,
    "show-footer": "",
    width: "80vw",
    height: "600",
    transfer: "",
    onClose: _ctx.close,
    onOrder: _ctx.order
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "button text-lg bg-theme-1 mr-2 text-white"
        }, "關閉")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.codeModel.number), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", _hoisted_7, [
                    _createVNode(_component_FontAwesome, {
                      icon: "qrcode",
                      class: "w-6 h-6"
                    })
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[0] || (_cache[0] = () => { _ctx.codeModel.number = ''; })
                  }, "清除"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[1] || (_cache[1] = () => { if(_ctx.codeModel.number.length > 0) _ctx.codeModel.number = _ctx.codeModel.number.substring(0, _ctx.codeModel.number.length - 1); })
                  }, [
                    _createVNode(_component_DeleteIcon)
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.codeModel.calculator(1)))
                  }, "1"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.codeModel.calculator(2)))
                  }, "2"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.codeModel.calculator(3)))
                  }, "3"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.codeModel.calculator(4)))
                  }, "4"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.codeModel.calculator(5)))
                  }, "5"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.codeModel.calculator(6)))
                  }, "6"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.codeModel.calculator(7)))
                  }, "7"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.codeModel.calculator(8)))
                  }, "8"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.codeModel.calculator(9)))
                  }, "9"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "number-btn",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.codeModel.calculator(0)))
                  }, "0"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "col-span-2 number-btn",
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.codeModel.check && _ctx.codeModel.check(...args)))
                  }, "確認")
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose", "onOrder"]))
}