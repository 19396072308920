
/* eslint-disable */
import { defineComponent, toRef, watch, ref, reactive, onMounted } from "vue";
import QrCode from "@/components/modal/QrCode.vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    QrCode
  },
  props: {
    visible: { type: Boolean, default: true },
    id: Number,
    data: {},
  },
  setup(props, { emit }) {
     const model = CloudFun.current?.model;
    const open = toRef(props, "visible");
    const confirmOpen = ref(false);
    const row = ref<any>({});
    const showQrcode = ref(false);

    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    const returnStations = ref<any>([]);
    const returnStation = ref<any>(null);
    const returnAreas = ref<any>([]);
    const returnArea = ref<any>(null);
    onMounted(async ()=> {
      const condition = new Condition("Published", Operator.Equal, true);
      returnAreas.value = await model!.dispatch('area/options', { keyword: null, condition: condition });
      returnStations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
    })

    const areaChange = async() => {
      returnStation.value = null;
      const condition = new Condition("Published", Operator.Equal, true);
      if (returnArea.value) {
        condition.and("AreaId", Operator.Equal, returnArea.value);
      }
      returnStations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
      // await grid.value.refresh();
    }

    const number_input = ref<any>(null);
    const returnModal = reactive({
      visible: true,
      selectedRow: {} as any,
      id: 0,
      number: '',
      returnNumber:[] as any[],
      show(row: any) {
        //console.log(row)
        returnModal.visible = true;
        returnModal.selectedRow = row;
        returnModal.id = row.Id;
        number_input.value?.focus();
      },
      returnBike: async(item: any) => {
        console.log(item.Bike)
        if(item.Bike.Status == 1) {
          var exist = returnModal.returnNumber.find(e => e == item.Bike.Number);
          if(exist) {
            returnModal.returnNumber = returnModal.returnNumber.filter(e => e != item.Bike.Number)
          } else {
            returnModal.returnNumber.push(item.Bike.Number);
          }
        }
        console.log(returnModal.returnNumber)
      },
      findBike: async() => {
        try {
          const number = returnModal.number;
          if(number) {
            const item = row?.value.Items?.find((e:any) => e.Bike != null && e.Bike.Number == number);
            //console.log(item)
            if (item) {
              returnModal.returnBike(item)
              returnModal.number = '';
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "查無車輛資訊"
              });
            }
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛編號"
            });
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          });
        }
      },
      save: async() => {
        try {
          if(returnModal.returnNumber?.length <= 0) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請選擇歸還車輛"
            });
            return;
          }
          if(!returnStation.value) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請選擇歸還站點"
            });
            return;
          }
          await model?.dispatch('order/return', { id: row.value.Id, bikeNos: returnModal.returnNumber, returnStationId: returnStation.value }).then((data) => {
            returnModal.reloadData()
          });
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          });
        }
        confirmOpen.value = false;
      },
      reset(){
        returnModal.number = '';
        returnModal.selectedRow = {} as any;
        returnModal.returnNumber = [] as any;
        returnModal.id = 0;
      },
      close() {
        returnModal.reset();
        emit("dismiss");
      },
      reloadData() {
        emit("reloadData");
      }
    })

    const inReturn = (number: any) => {
      if(!number) return false;
      const result = returnModal.returnNumber.some(e => e == number);
      //console.log(result);
      return result;
    }

    const qrcodeDecode = async (value: any) => {
      console.log(value)
      if(value) {
        returnModal.number = value;
        await returnModal.findBike();
      }
    };

    function addHours(numOfHours: any, date: any) {
      date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
      return date;
    }

    const returnCount = (type: boolean) => {
      var count = row?.value.Items?.filter((e:any) => e.Bike != null && e.Bike.Status == 0)?.length + returnModal.returnNumber?.length ?? 0;
      var rent = row?.value.Items?.filter((e:any) => e.Bike != null && e.Bike.Status == 1 && !returnModal.returnNumber.some(i => i == e.Bike.Number))?.length;
      return type ? count : rent;
    }

    function timeDiff (startDate: any, endDate: any) {
      console.log(startDate, endDate)
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff/1000/60/60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff/1000/60);
      diff -= minutes * 1000 * 60;
      var seconds = Math.floor(diff/1000);
      return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
    }

    function overCharge (items: any) {
      var result = 0;
      if(items) {
        var list = items.filter((e: any) => e.Product && !e.Product.Id && e.BikeId == null);
        console.log(items)
        if(list && list.length) {
          list.forEach((e: any) => {
            result += e.Amount;
          });
        }
      }
      return result;
    }

    watch(() => props.visible, async (value: any) => {
      if(value) {
        row.value = {};
        returnModal.reset();
        const entity = props.id ? await model!.dispatch('order/find', props.id) : undefined
        row.value = entity;
        row.value.InsuranceRecords = await model?.dispatch("insuranceRecord/query", { condition: new Condition("OrderId", Operator.Equal, row.value.Id) });
        number_input.value?.focus();
        if(row.value?.ReturnStationId) returnStation.value = row.value.ReturnStationId;
        console.log(row.value)
      }
    }, { immediate: true });

    return {
      open,
      row,
      formatDate,
      showQrcode,
      returnModal,
      qrcodeDecode,
      inReturn,
      number_input,
      addHours,
      returnCount,
      timeDiff,
      overCharge,
      returnStations,
      returnStation,
      returnArea,
      returnAreas,
      areaChange,
      insurance: [
        {
          id: 1,
          name: "林先生",
          userId: "E121045677",
          bday: "1991/01/05",
          tel: "0988030000"
        },
        {
          id: 2,
          name: "王小姐",
          userId: "E225045612",
          bday: "1971/11/15",
          tel: "0988123000"
        },
      ],
      bikeList: [
        {
          id: 1,
          name: "單人電輔車",
          number: "A002",
          numberClass: "text-theme-1",
          active: "車輛歸還",
          activeClass: "text-theme-1 border-theme-4",
          stateClass: "border-theme-4 border-2",
          priceClass: "text-yellow-500",
          priceTime: "100",
          priceStation: "50",
        },
        {
          id: 2,
          name: "單人電輔車",
          number: "A001",
          numberClass: "text-gray-300",
          active: "已歸還",
          activeClass: "text-gray-300 border-theme-200 px-6",
          stateClass: "border-gray-200 border",
          priceClass: "text-gray-400",
          priceTime: "0",
          priceStation: "0",
        },
      ]
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});

